.ContentBlock {
    padding: 64px 32px;
    flex-direction: column;
    flex: 1;
}

.ContentBlock img {
    max-width: 90%;
    height: auto !important;
}

.ContentBlock .ContentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-top: 48px;
    margin-bottom: 48px;
}

.ContentBlock .Content {
    width: 90%;
    text-align: center;
    outline: none;
    max-width: 950px;
    padding: 4px;
}

.ContentBlock h2 {
    font-size: 2.5rem;
}

.ContentBlock p {
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2rem;
}

.ContentBlock li {
    font-size: 18px;
    line-height: 1.6;
}