.ChapterBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
  flex: 1;
}

.ChapterBlock_right,
.ChapterBlock_left {
  max-width: 950px;
  width: 90%;
  margin: 0 auto;
}

.ChapterBlock h1 {
  text-align: center;
}

.ChapterBlock_left h1 {
  text-align: left;
}

.ChapterBlock_right h1 {
  text-align: right;
}

.ChapterBlock .input {
  flex: 1;
}

#ChapterNameField {
  background: none;
  outline: none;
  border: none;
  padding: 4px 16px;
  flex: 1;
  line-height: 100px;
  text-align: center;
}

.ChapterBlock_left #ChapterNameField,
.ChapterBlock_right #ChapterNameField {
  padding: 4px;
}

.ChapterBlock_left #ChapterNameField {
  text-align: left;
}

.ChapterBlock_right #ChapterNameField {
  text-align: right;
}

#ChapterNameField::selection {
  color: rgba(255, 255, 255, 0.8);
}
