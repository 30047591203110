.VideoBlock {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 0;
}

.VideoBlock .Editor {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.VideoBlock .Editor .UrlEdit {
  padding: 2rem;
}

.VideoBlock .h2 {
  margin: auto;
  font-size: 34px !important;
  font-weight: 300 !important;
}

.VideoBlock .Editor button {
  margin-top: 16px;
}

.VideoBlock .Editor .Error {
  color: red;
  text-align: center;
  margin-top: 8px;
}

.VideoBlock .EmbeddedContent {
  display: flex;
  margin: auto;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
}

.VideoBlock .UrlEdit {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8px;
}

.VideoBlock .UrlEdit .Caption {
  font-size: 20px;
  color: #4a4a4a;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 16px;
}

.VideoBlock .UrlEdit .Description {
  margin-top: 24px;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 1px;
  margin-bottom: 24px;
  text-align: center;
}

.VideoBlock #urlField {
  font-size: 12px;
}

.VideoBlock #urlField + button {
  background: #1ca8bd;
  color: white;
  font-weight: 300;
}

.VideoBlock #videoTitle {
  border: none;
  text-align: center;
  margin-bottom: 48px;
  line-height: 50px;
}
