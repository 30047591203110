.BlockBase {
  position: relative;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BlockBase .BlockToolbar {
  position: absolute;
  top: 8px;
  left: 64px;
}

.BlockBase .BlockToolbar .ButtonRow {
  display: flex;
  flex-direction: row;
}

#AddBlock {
  width: 50px;
  height: 50px;
  position: absolute;
  left: calc(50% - 25px);
  top: 8px;
}

.ColorButton {
  margin: 4px;
  border: none;
  outline: none;
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.FileSelector {
  width: 100%;
  padding: 8px 0;
  margin: 8px 0;
  background: #f8f8f8;
  border: 1px dashed #d8d8d8;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.FileSelector img {
  margin-bottom: 16px;
}

.FileSelector p {
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
  font-size: 12px;
  color: #999999;
  letter-spacing: 1px;
  line-height: 11px;
  margin: 0;
}

.BlockBase hr {
  background: url("./spacer.svg") no-repeat center;
  height: 20px;
  border: none;
}

.BlockBase hr.HR_left {
  background: url("./spacer.svg") no-repeat left;
}

.BlockBase hr.HR_right {
  background: url("./spacer.svg") no-repeat right;
}

.BlockThemeSelector {
  width: 285px;
  padding: 8px;
}

.BlockThemeSelector span {
  font-size: 10px;
  color: #4a4a4a;
  letter-spacing: 1px;
  text-align: left;
  display: block;
}
