.EmbeddedChapter {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px 0;
    flex: 1;
}

.Editor {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Editor #embedTitle {
    text-align: center;
}

.EmbeddedChapter .Editor .UrlEdit {
    padding: 2rem;
}

.EmbeddedChapter .h2 {
    margin: auto;
    font-size: 34px !important;
}

.EmbeddedChapter #embedTitle {
    border: none;
    text-align: center;
    margin-bottom: 48px;
    line-height: 50px;
}

.EmbeddedChapter .Editor button {
    margin-top: 16px;
}

.EmbeddedChapter .Editor .Error {
    color: red;
    text-align: center;
    margin-top: 8px;
}

.EmbeddedChapter .EmbeddedContent {
    display: flex;
    margin: auto;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-direction: column;
}

.EmbeddedChapter .EmbeddedContent .ui.fluid.input {
    width: 90%;
}

.EmbeddedChapter .UrlEdit {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 8px;
}

.EmbeddedChapter .UrlEdit .Caption {
    font-size: 20px;
    color: #4A4A4A;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 16px;
}

.EmbeddedChapter .UrlEdit .Description {
    margin-top: 24px;
    font-size: 12px;
    color: #4A4A4A;
    letter-spacing: 1px;
    margin-bottom: 24px;
    text-align: center;
}

.EmbeddedChapter #urlField {
    font-size: 12px;
}

.EmbeddedChapter #urlField + button {
    background: #1CA8BD;
    color: white;
    font-weight: 300;
}

.EmbeddedChapter #videoTitle {
    border: none;
    text-align: center;
    margin-bottom: 48px;
    line-height: 50px;
}
